<template>
  <div class="main" v-if="show">
    <header>
      <nav class="ltx-header bg-white border-b border-gray-200">
        <div class="mx-auto px-4 sm:px-6 lg:px-6 lg:pl-3">
          <div class="flex justify-between h-16">
            <div
              v-if="
                project[0].project_slug === 'eucerin' ||
                project[0].project_slug === 'eucerin-test'
              "
              class="space"
            ></div>
            <div class="flex-shrink-0 flex items-center">
              <a :href="`/${project[0].project_slug}`">
                <img
                  id="logo"
                  class="block h-8 w-auto"
                  :src="project[0].logo"
                />
              </a>
            </div>
            <div class="section-logout hidden sm:ml-6 sm:flex sm:items-center">
              <!-- Profile dropdown -->
              <div class="ml-3 relative">
                <div>
                  <button
                    @click="logout()"
                    class="
                      bg-title bg-red-600
                      hover:bg-red-700
                      text-white
                      font-bold
                      py-1
                      px-4
                      rounded-full
                    "
                  >
                    Logout
                  </button>
                </div>
                <!---->
              </div>
            </div>
            <div class="-mr-2 flex items-center sm:hidden">
              <!-- Mobile menu button -->
              <button
                id="headlessui-disclosure-button-3"
                type="button"
                class="
                  bg-white
                  inline-flex
                  items-center
                  justify-center
                  p-2
                  rounded-md
                  text-gray-400
                  hover:text-gray-500
                  hover:bg-gray-100
                  focus:outline-none
                  focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                "
              >
                <span class="sr-only">Open main menu</span
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  class="block h-6 w-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <!---->
      </nav>
    </header>
    <div class="main-screen flex">
      <div class="sidebar hidden xl:block xs:flex xs:flex-shrink-0">
        <div class="w-24 flex flex-col">
          <!-- Sidebar component, swap this element with another sidebar if you like -->
          <div
            class="
              border-r border-gray-200
              pt-5
              pb-4
              flex flex-col flex-grow
              overflow-y-auto
            "
          >
            <div class="flex-grow flex flex-col">
              <nav class="flex-1 bg-white px-2 space-y-2">
                <a
                  href="#"
                  class="
                    bg-gray-100
                    text-gray-900
                    group
                    rounded-md
                    py-2
                    px-2
                    w-full
                    block
                    items-center
                    text-sm
                    font-medium
                  "
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    class="text-gray-500 mr-3 h-6 w-full"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                    ></path>
                  </svg>
                  <span class="w-full inline-block text-center">Live</span></a
                >
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full bg-gray-100 overflow-hidden flex justify-between">
        <main class="w-full justify-between sm:py-5">
          <div
            class="
              max-w-3xl
              mx-auto
              grid grid-cols-1
              gap-6
              lg:px-6
              lg:max-w-7xl
              lg:grid-flow-col-dense lg:grid-cols-3
            "
          >
            <div
              class="
                main-section
                lg:col-start-1 lg:col-span-2
                bg-white
                shadow
                rounded-none
              "
            >
              <!-- Description list-->

              <section
                aria-labelledby="applicant-information-title"
                class="media"
              >
                <div class="video-container">
                  <img
                    v-if="
                      (session && session.status === 'landing_page') ||
                      session.status === 'live_end'
                    "
                    class="block w-full"
                    :src="session.video_thumbnail"
                  />
                  <div v-else>
                    <div
                      class="video"
                      v-if="session.player_type === 'iframe'"
                      :key="videoComponentKey"
                    >
                      <div class="iframe-container">
                        <iframe
                          id="player"
                          frameborder="0"
                          :src="video_url"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                          allowfullscreen
                          referrerpolicy="strict-origin-when-cross-origin"
                        />
                      </div>
                    </div>
                    <div class="video" v-else>
                      <div v-if="selectedPlay == 'main'">
                        <div class="iframe-container">
                          <iframe
                            id="player"
                            frameborder="0"
                            :src="video_url"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                            allowfullscreen
                            referrerpolicy="strict-origin-when-cross-origin"
                          />
                        </div>
                      </div>
                      <div v-else>
                        <div id="video" />
                      </div>
                    </div>
                  </div>
                  <div
                    class="
                      session-mobile
                      bg-white
                      pt-4
                      pb-0
                      px-4
                      player-bottom-bar
                      block
                      md:hidden
                    "
                  >
                    <h2
                      class="title-mobile text-xl"
                      v-html="session.session_name"
                    />
                    <div class="block mt-2">
                      <div class="flex justify-between items-baseline">
                        <nav
                          v-if="
                            (session.status === 'live' &&
                              session.video_url !== '') ||
                            (session.status === 'live_countdown' &&
                              session.video_url !== '')
                          "
                          class="stream-tabs flex space-x-4"
                          aria-label="Tabs"
                        >
                          <!-- Current: "bg-indigo-100 text-indigo-700", Default: "text-gray-500 hover:text-gray-700" -->
                          <a
                            class="px-3 py-2 font-medium text-sm rounded-md"
                            :class="{
                              'bg-red-600': videoSource === 0,
                              'text-white': videoSource === 0,
                              'text-gray-500': videoSource !== 0,
                              'hover:text-gray-700': videoSource !== 0,
                            }"
                            @click="selectSource(0)"
                          >
                            Live
                          </a>
                          <a
                            class="px-3 py-2 font-medium text-sm rounded-md"
                            :class="{
                              'bg-red-600': videoSource === 1,
                              'text-white': videoSource === 1,
                              'text-gray-500': videoSource !== 1,
                              'hover:text-gray-700': videoSource !== 1,
                            }"
                            @click="selectSource(1)"
                          >
                            Backup
                          </a>
                        </nav>
                        <div class="mt-1 whitespace-nowrap sm:mt-0 sm:ml-3">
                          <span
                            v-if="
                              session.status === 'landing_page' ||
                              session.status === 'landing_page_video' ||
                              session.status === 'live_countdown'
                            "
                            color="blue"
                            class="
                              countdown
                              px-2.5
                              py-0.5
                              rounded-md
                              text-sm
                              font-medium
                              bg-blue-100
                              text-blue-800
                            "
                          >
                            {{ relativeTime(session.start_time) }}
                          </span>
                          <span
                            v-if="session.status === 'live'"
                            class="
                              inline-flex
                              items-center
                              px-2.5
                              py-0.5
                              rounded-md
                              text-sm
                              font-medium
                              bg-red-100
                              text-red-800
                            "
                          >
                            กำลังถ่ายทอดสด
                          </span>
                          <span
                            v-if="
                              session.status === 'live_end' ||
                              session.status === 'live_end_video'
                            "
                            class="
                              inline-flex
                              items-center
                              px-2.5
                              py-0.5
                              rounded-md
                              text-sm
                              font-medium
                              bg-indigo-100
                              text-indigo-800
                            "
                          >
                            จบการถ่ายทอดสด
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      project[0].project_slug !== 'eucerin' &&
                      project[0].project_slug !== 'eucerin-test'
                    "
                    class="description-mobile p-4 bg-white"
                  >
                    <div
                      class="
                        text-description-mobile
                        bg-blue-50
                        border-l-4 border-blue-300
                        text-gray-700
                        p-2
                        shadow-md
                        block
                        lg:hidden
                      "
                    >
                      <p
                        v-if="
                          project[0].project_slug == 'kingdrag-thailand-2024'
                        "
                        class="
                          flex flex-wrap
                          gap-2
                          items-center
                          text-md text-description
                        "
                      >
                        <span class="ml-1 inline-block">ดูย้อนหลัง</span>
                        <a
                          href="/kingdrag-thailand-2024/sessions/kingdrag-thailand-2024-king-drag-thailand-2024-day-1-(12)"
                          class="
                            px-2
                            py-1
                            border border-white
                            rounded-md
                            text-white
                            hover:bg-white
                            hover:border-white
                            hover:text-black
                          "
                          >29 พ.ย. 67 1/2</a
                        >
                        <a
                          href="/kingdrag-thailand-2024/sessions/kingdrag-thailand-2024-king-drag-thailand-2024-day-1-(22)"
                          class="
                            px-2
                            py-1
                            border border-white
                            rounded-md
                            text-white
                            hover:bg-white
                            hover:border-white
                            hover:text-black
                          "
                          >29 พ.ย. 67 2/2</a
                        >
                        <a
                          href="/kingdrag-thailand-2024/sessions/kingdrag-thailand-2024-king-drag-thailand-2024-day-2-(12)"
                          class="
                            px-2
                            py-1
                            border border-white
                            rounded-md
                            text-white
                            hover:bg-white
                            hover:border-white
                            hover:text-black
                          "
                          >30 พ.ย. 67 1/2</a
                        >
                        <a
                          href="/kingdrag-thailand-2024/sessions/kingdrag-thailand-2024-king-drag-thailand-2024-day-2-(22)"
                          class="
                            px-2
                            py-1
                            border border-white
                            rounded-md
                            text-white
                            hover:bg-white
                            hover:border-white
                            hover:text-black
                          "
                          >30 พ.ย. 67 2/2</a
                        >
                        <a
                          href="/kingdrag-thailand-2024/sessions/kingdrag-thailand-2024-king-drag-thailand-2024-day-3-(12)"
                          class="
                            px-2
                            py-1
                            border border-white
                            rounded-md
                            text-white
                            hover:bg-white
                            hover:border-white
                            hover:text-black
                          "
                          >1 ธ.ค. 67 1/2</a
                        >
                        <a
                          href="/kingdrag-thailand-2024/sessions/kingdrag-thailand-2024-king-drag-thailand-2024-day-3-(22)"
                          class="
                            px-2
                            py-1
                            border border-white
                            rounded-md
                            text-white
                            hover:bg-white
                            hover:border-white
                            hover:text-black
                          "
                          >1 ธ.ค. 67 2/2</a
                        >
                      </p>
                      <p v-else class="text-md text-description">
                        หากพบปัญหาการรับชม ท่านสามารถติดต่อ LINE :
                        <a
                          href="https://line.me/R/ti/p/@376hyqso"
                          target="_blank"
                          >@376hyqso</a
                        >
                        ค่ะ
                      </p>
                    </div>
                  </div>

                  <div
                    v-if="ios && mobileonly"
                    class="
                      chat-submit-form-mobile
                      w-full
                      flex
                      justify-between
                      bg-white
                      border-t
                      lg:hidden
                    "
                  >
                    <textarea
                      class="
                        chat-text
                        flex-grow
                        m-2
                        py-2
                        px-4
                        mr-1
                        rounded-full
                        border border-gray-300
                        bg-gray-200
                        focus:ring-red-500
                        focus:border-red-500
                        resize-none
                      "
                      :class="[isFocus ? 'is-focus' : 'not-focus']"
                      rows="1"
                      name="message"
                      placeholder="พิมพ์ข้อความ..."
                      style="outline: none"
                      v-model="new_message"
                      @keyup.enter="submitChat"
                      @focusout="focus(false)"
                      @focusin="focus(true)"
                    ></textarea>
                    <button
                      class="m-2"
                      style="outline: none"
                      @click="submitChat"
                    >
                      <svg
                        class="
                          svg-inline--fa
                          text-gray-500
                          fa-paper-plane fa-w-16
                          w-12
                          h-12
                          py-2
                          mr-2
                        "
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="paper-plane"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </section>
              <div class="pt-2 pb-4 px-4 hidden md:block">
                <!-- <div class="flex items-center justify-between relative">
                  <h2 class="title pt-2 py-4 pr-4">
                    {{ session.session_name }}
                  </h2>
                  <span
                    class="
                      countdown
                      px-2.5
                      py-0.5
                      rounded-md
                      text-sm
                      font-medium
                      bg-blue-100
                      text-blue-800
                    "
                  >
                    {{ relativeTime(session.start_time) }}
                  </span>
                </div> -->
                <div class="flex justify-between items-baseline">
                  <h2
                    class="title pt-2 py-4 pr-4"
                    v-html="session.session_name"
                  />
                  <div class="mt-1 whitespace-nowrap sm:mt-0 sm:ml-3">
                    <span
                      v-if="
                        session.status === 'landing_page' ||
                        session.status === 'landing_page_video' ||
                        session.status === 'live_countdown'
                      "
                      color="blue"
                      class="
                        countdown
                        px-2.5
                        py-0.5
                        rounded-md
                        text-sm
                        font-medium
                        bg-blue-100
                        text-blue-800
                      "
                    >
                      {{ relativeTime(session.start_time) }}
                    </span>
                    <span
                      v-if="session.status === 'live'"
                      class="
                        inline-flex
                        items-center
                        px-2.5
                        py-0.5
                        rounded-md
                        text-sm
                        font-medium
                        bg-red-100
                        text-red-800
                      "
                    >
                      กำลังถ่ายทอดสด
                    </span>
                    <span
                      v-if="
                        session.status === 'live_end' ||
                        session.status === 'live_end_video'
                      "
                      class="
                        inline-flex
                        items-center
                        px-2.5
                        py-0.5
                        rounded-md
                        text-sm
                        font-medium
                        bg-indigo-100
                        text-indigo-800
                      "
                    >
                      จบการถ่ายทอดสด
                    </span>
                  </div>
                </div>
                <div
                  v-if="
                    (session.status === 'live' && session.video_url !== '') ||
                    (session.status === 'live_countdown' &&
                      session.video_url !== '')
                  "
                  class="hidden md:mb-4 mb-2"
                >
                  <nav class="flex space-x-4" aria-label="Tabs">
                    <!-- Current: "bg-indigo-100 text-indigo-700", Default: "text-gray-500 hover:text-gray-700" -->
                    <a
                      class="px-3 py-2 font-medium text-sm rounded-md"
                      :class="{
                        'bg-red-600': videoSource === 0,
                        'text-white': videoSource === 0,
                        'text-gray-500': videoSource !== 0,
                        'hover:text-gray-700': videoSource !== 0,
                      }"
                      @click="selectSource(0)"
                    >
                      Live
                    </a>

                    <!-- <a
                      class="px-3 py-2 font-medium text-sm rounded-md"
                      :class="{
                        'bg-indigo-100': videoSource === 1,
                        'text-indigo-700': videoSource === 1,
                        ': videoSource !== 1,
                        'hover:text-gray-700': videoSource !== 1,
                      }"
                      @click="selectSource(1)"
                    >
                      Interpreter
                    </a> -->

                    <a
                      class="px-3 py-2 font-medium text-sm rounded-md"
                      :class="{
                        'bg-red-600': videoSource === 1,
                        'text-white': videoSource === 1,
                        'text-gray-500': videoSource !== 1,
                        'hover:text-gray-700': videoSource !== 1,
                      }"
                      @click="selectSource(1)"
                    >
                      Backup
                    </a>

                    <!-- <a
                      class="px-3 py-2 font-medium text-sm rounded-md"
                      :class="{
                        'bg-indigo-100': videoSource === 3,
                        'text-indigo-700': videoSource === 3,
                        'text-gray-500': videoSource !== 3,
                        'hover:text-gray-700': videoSource !== 3,
                      }"
                      @click="selectSource(3)"
                    >
                      Interpreter (Backup)
                    </a> -->
                  </nav>
                </div>
                <div
                  v-if="
                    project[0].project_slug !== 'eucerin' &&
                    project[0].project_slug !== 'eucerin-test'
                  "
                  class="
                    description
                    bg-blue-50
                    border-l-4 border-blue-300
                    text-gray-700
                    p-4
                    shadow-md
                    hidden
                  "
                >
                  <p
                    v-if="project[0].project_slug == 'kingdrag-thailand-2024'"
                    class="
                      flex flex-wrap
                      gap-2
                      items-center
                      text-md text-description
                    "
                  >
                    <span class="ml-1 inline-block">ดูย้อนหลัง</span>
                    <a
                      href="/kingdrag-thailand-2024/sessions/kingdrag-thailand-2024-king-drag-thailand-2024-day-1-(12)"
                      class="
                        px-2
                        py-1
                        border border-white
                        rounded-md
                        text-white
                        hover:bg-white
                        hover:border-white
                        hover:text-black
                      "
                      >29 พ.ย. 67 1/2</a
                    >
                    <a
                      href="/kingdrag-thailand-2024/sessions/kingdrag-thailand-2024-king-drag-thailand-2024-day-1-(22)"
                      class="
                        px-2
                        py-1
                        border border-white
                        rounded-md
                        text-white
                        hover:bg-white
                        hover:border-white
                        hover:text-black
                      "
                      >29 พ.ย. 67 2/2</a
                    >
                    <a
                      href="/kingdrag-thailand-2024/sessions/kingdrag-thailand-2024-king-drag-thailand-2024-day-2-(12)"
                      class="
                        px-2
                        py-1
                        border border-white
                        rounded-md
                        text-white
                        hover:bg-white
                        hover:border-white
                        hover:text-black
                      "
                      >30 พ.ย. 67 1/2</a
                    >
                    <a
                      href="/kingdrag-thailand-2024/sessions/kingdrag-thailand-2024-king-drag-thailand-2024-day-2-(22)"
                      class="
                        px-2
                        py-1
                        border border-white
                        rounded-md
                        text-white
                        hover:bg-white
                        hover:border-white
                        hover:text-black
                      "
                      >30 พ.ย. 67 2/2</a
                    >
                    <a
                      href="/kingdrag-thailand-2024/sessions/kingdrag-thailand-2024-king-drag-thailand-2024-day-3-(12)"
                      class="
                        px-2
                        py-1
                        border border-white
                        rounded-md
                        text-white
                        hover:bg-white
                        hover:border-white
                        hover:text-black
                      "
                      >1 ธ.ค. 67 1/2</a
                    >
                    <a
                      href="/kingdrag-thailand-2024/sessions/kingdrag-thailand-2024-king-drag-thailand-2024-day-3-(22)"
                      class="
                        px-2
                        py-1
                        border border-white
                        rounded-md
                        text-white
                        hover:bg-white
                        hover:border-white
                        hover:text-black
                      "
                      >1 ธ.ค. 67 2/2</a
                    >
                  </p>
                  <p v-else class="text-md text-description">
                    หากพบปัญหาการรับชม ท่านสามารถติดต่อ LINE :
                    <a href="https://line.me/R/ti/p/@376hyqso" target="_blank"
                      >@376hyqso</a
                    >
                    ค่ะ
                  </p>
                  <iframe
                    src="https://www.kingdragthailand.com/pixel"
                    allow="screen-wake-lock"
                    width="1"
                    height="1"
                  />
                </div>
              </div>
              <!-- Comments-->
            </div>
            <section
              class="
                h-full
                lg:py-4
                md:p-2
                pb-16
                lg:col-start-3 lg:col-span-1
                right-sidebar
                bg-white
                shadow
                rounded-none
              "
            >
              <div v-if="session.session_desc !== ''" class="announcement">
                <div v-if="announcementEdit" class="rounded-md bg-blue-50 p-4">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <!-- Heroicon name: solid/exclamation -->
                      <svg
                        class="h-5 w-5 text-blue-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <div class="ml-3">
                      <h3 class="text-md font-bold text-gray-700">ประกาศ</h3>
                      <div class="mt-2 text-sm text-gray-600">
                        <p>
                          {{ announcement }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="rounded-md bg-blue-50 p-4">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <!-- Heroicon name: solid/exclamation -->
                      <svg
                        class="h-5 w-5 text-blue-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <div class="ml-3">
                      <h3 class="text-md font-bold text-gray-700">ประกาศ</h3>
                      <div class="mt-2 text-sm text-gray-600">
                        <p>
                          {{ session.session_desc }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="announcementEdit" class="announcement-editor mt-2">
                <textarea
                  class="
                    shadow-sm
                    block
                    w-full
                    focus:ring-red-500
                    focus:border-red-500
                    sm:text-sm
                    border border-gray-300
                    rounded-md
                    message-input
                  "
                  rows="2"
                  style="outline: none"
                  v-model="announcement"
                ></textarea>
              </div>
              <div
                v-if="isAdmin"
                class="mt-3 mb-4 flex items-center justify-between"
              >
                <div></div>
                <div class="flex justify-end">
                  <button
                    v-if="announcementEdit === false"
                    type="button"
                    class="
                      btn
                      inline-flex
                      items-center
                      px-2.5
                      py-1.5
                      border border-transparent
                      text-md
                      font-medium
                      rounded
                      shadow-sm
                      text-white
                      bg-red-600
                      hover:bg-red-700
                      focus:outline-none
                      focus:ring-2 focus:ring-offset-2 focus:ring-red-500
                    "
                    @click="setAnnouncementEdit()"
                  >
                    {{
                      session.session_desc === ''
                        ? 'เพิ่มประกาศ'
                        : 'แก้ไขประกาศ'
                    }}
                  </button>
                  <button
                    v-else
                    type="button"
                    class="
                      inline-flex
                      items-center
                      px-2.5
                      py-1.5
                      border border-transparent
                      text-xs
                      font-medium
                      rounded
                      shadow-sm
                      text-white
                      bg-red-600
                      hover:bg-red-700
                      focus:outline-none
                      focus:ring-2 focus:ring-offset-2 focus:ring-red-500
                    "
                    @click="updateAnnouncement"
                  >
                    Save
                  </button>
                  <button
                    v-if="announcementEdit"
                    type="button"
                    class="
                      ml-2
                      inline-flex
                      items-center
                      px-2.5
                      py-1.5
                      border border-gray-300
                      shadow-sm
                      text-xs
                      font-medium
                      rounded
                      text-gray-700
                      bg-white
                      hover:bg-gray-50
                      focus:outline-none
                      focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                    "
                    @click="announcementEdit = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div
                class="
                  qa-form
                  md:col-start-3
                  lg:col-start-4 lg:col-span-1
                  gridgrid-cols-1
                  grid-flow-col-dense
                  sm:overflow-hidden
                "
              >
                <div v-if="!$isMobile()">
                  <div class="sm:block">
                    <div class="border-b border-gray-200">
                      <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                        <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
                        <a
                          v-if="chatTab"
                          href="#"
                          class="
                            tab-chat
                            mt-4
                            border-transparent
                            text-gray-500
                            hover:text-red-600
                            hover:border-red-600
                            group
                            inline-flex
                            items-center
                            py-4
                            px-4
                            border-b-2
                            font-medium
                            text-sm
                            hidden
                            lg:block
                          "
                          :class="{
                            'border-red-600 text-red-600':
                              currentTab === 'tab-chat',
                          }"
                          aria-current="page"
                          @click="currentTab = 'tab-chat'"
                        >
                          <!-- Heroicon name: solid/users -->
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                            />
                          </svg>
                          <span class="title-chat ml-2 font-bold text-base"
                            >CHAT
                          </span>
                        </a>
                        <a
                          v-if="qaTab"
                          href="#"
                          class="
                            border-transparent
                            text-gray-500
                            hover:text-red-600
                            hover:border-red-600
                            group
                            inline-flex
                            items-center
                            py-4
                            px-1
                            border-b-2
                            font-medium
                            text-sm
                          "
                          :class="{
                            'border-red-600 text-red-600':
                              currentTab === 'tab-qa',
                          }"
                          aria-current="page"
                          @click="currentTab = 'tab-qa'"
                        >
                          <!-- Heroicon name: solid/users -->
                          <svg
                            class="-ml-0.5 mr-2 h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                            :class="{
                              'text-red-600': currentTab === 'tab-qa',
                            }"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          <span>Q&amp;A</span>
                        </a>
                        <a
                          v-if="pollTab"
                          href="#"
                          class="
                            border-transparent
                            text-gray-500
                            hover:text-red-600
                            hover:border-red-600
                            group
                            inline-flex
                            items-center
                            py-4
                            px-1
                            border-b-2
                            font-medium
                            text-sm
                          "
                          :class="{
                            'border-red-600 text-red-600':
                              currentTab === 'tab-poll',
                          }"
                          @click="currentTab = 'tab-poll'"
                        >
                          <svg
                            class="
                              text-gray-400
                              group-hover:text-red-600
                              -ml-0.5
                              mr-2
                              h-5
                              w-5
                            "
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                            :class="{
                              'text-red-600': currentTab === 'tab-poll',
                            }"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          <span>Polls</span>
                        </a>
                      </nav>
                    </div>
                  </div>
                </div>
                <div class="tab-content">
                  <div id="tab-chat" v-if="currentTab === 'tab-chat'">
                    <div
                      v-if="tablet"
                      class="
                        w-full
                        flex
                        items-center
                        justify-between
                        hidden
                        lg:inline-flex
                        chat-submit-form-tablet
                        bg-white
                      "
                    >
                      <textarea
                        class="
                          flex-grow
                          m-2
                          py-2
                          px-4
                          mr-1
                          rounded-full
                          border border-gray-300
                          bg-white
                          resize-none
                          w-9/12
                        "
                        rows="1"
                        placeholder="พิมพ์ข้อความ..."
                        style="outline: none"
                        v-model="new_message"
                        @keyup.enter="submitChat"
                      ></textarea>
                      <button
                        class="m-2"
                        style="outline: none"
                        @click="submitChat"
                      >
                        <svg
                          class="
                            btn-send
                            svg-inline--fa
                            text-green-400
                            fa-paper-plane fa-w-16
                            w-12
                            h-12
                            py-2
                            mr-2
                            mb-2
                          "
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="paper-plane"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"
                          />
                        </svg>
                      </button>
                    </div>
                    <div
                      class="chat-message-box px-4 py-6 sm:px-2 pb-16 xl:pb-6"
                    >
                      <div
                        class="space-y-8 chat-row my-4"
                        v-for="(item, index) in messageFormat"
                        :key="index"
                      >
                        <div
                          class="chat-message-container"
                          :class="{ admin: item.role === 'admin' }"
                        >
                          <div v-if="item.reply" class="reply">
                            <div class="reply-label">
                              {{ item.author }} ได้ตอบกลับ
                              {{ item.reply.author }}
                            </div>
                            <div class="original-message">
                              <div
                                class="chat-message"
                                v-html="item.reply.message"
                                v-linkified
                              />
                            </div>
                            <div class="reply-message">
                              <div class="user-box">
                                <div class="user">
                                  {{ item.author }}
                                </div>
                                <div class="time">
                                  {{ timeFormat(item.timestamp) }}
                                </div>
                              </div>
                              <div
                                class="chat-message"
                                v-html="item.message"
                                v-linkified
                              />
                            </div>
                          </div>
                          <div v-else class="original">
                            <div class="original-message">
                              <div class="user-box">
                                <div class="user">
                                  {{ item.author }}
                                </div>
                                <div class="time">
                                  {{ timeFormat(item.timestamp) }}
                                </div>
                              </div>
                              <div
                                class="chat-message"
                                v-html="item.message"
                                v-linkified
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="android" class="mt-10"></div>
                      <div
                        v-if="screenMd"
                        ref="scrollable"
                        :class="{ 'mt-20': tablet }"
                      />
                    </div>
                    <!-- <div class="flex justify-center new-message-badge">
                      <span
                        v-if="newMessage"
                        class="
                          badge
                          inline-flex
                          items-center
                          py-0.5
                          pl-2
                          px-0.5
                          rounded-full
                          text-xs
                          font-medium
                          bg-indigo-100
                          text-indigo-700
                        "
                        @click="goToNewMessage()"
                      >
                        Scroll to last message
                      </span>
                    </div> -->

                    <!-- <div
                      class="
                        mt-8
                        chat-submit-form
                        bg-gray-100
                        px-4
                        py-6
                        sm:px-6
                        hidden
                        lg:block
                      "
                    >
                      <div class="flex space-x-3">
                        <div class="min-w-0 flex-1">
                          <div>
                            <textarea
                              id="chat"
                              name="chat"
                              rows="2"
                              class="
                                shadow-sm
                                block
                                w-full
                                focus:ring-red-500
                                focus:border-red-500
                                sm:text-sm
                                border border-gray-300
                                rounded-md
                                message-input
                              "
                              placeholder=""
                              v-model="new_message"
                              @keyup.enter="submitChat"
                            ></textarea>
                          </div>
                          <div class="mt-3 flex items-center justify-between">
                            <button
                              type="submit"
                              class="
                                btn
                                inline-flex
                                items-center
                                justify-center
                                px-4
                                py-2
                                text-md
                                font-bold
                                rounded-md
                                shadow-sm
                                text-white
                                bg-red-600
                                hover:bg-red-700
                                focus:outline-none
                                focus:ring-2
                                focus:ring-offset-2
                                focus:ring-blue-500
                                disabled:opacity-50
                              "
                              @click="submitChat()"
                              :disabled="
                                submitChatLoading || new_message === ''
                              "
                            >
                              ส่งข้อความ
                              <svg
                                v-if="submitChatLoading"
                                class="
                                  animate-spin
                                  ml-2
                                  mr-3
                                  h-5
                                  w-5
                                  text-white
                                "
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  class="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  stroke-width="4"
                                ></circle>
                                <path
                                  class="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            </button>
                            <button
                              v-if="isAdmin"
                              type="submit"
                              class="
                                btn
                                inline-flex
                                items-center
                                justify-center
                                px-4
                                py-2
                                border border-transparent
                                text-sm
                                font-bold
                                rounded-md
                                shadow-sm
                                text-white
                                bg-red-600
                                hover:bg-red-700
                                focus:outline-none
                                focus:ring-2
                                focus:ring-offset-2
                                focus:ring-blue-500
                                disabled:opacity-50
                              "
                              @click="goToChatManagement"
                            >
                              Chat management
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> -->
                    <div
                      v-if="!mobile"
                      class="
                        w-full
                        flex
                        items-center
                        justify-between
                        chat-submit-form-desktop
                        bg-white
                        hidden
                        lg:block
                      "
                    >
                      <textarea
                        class="
                          chat-text
                          flex-grow
                          m-2
                          py-2
                          px-4
                          mr-1
                          rounded-full
                          border border-gray-300
                          bg-white
                          resize-none
                          w-9/12
                        "
                        rows="1"
                        name="message"
                        placeholder="พิมพ์ข้อความ..."
                        style="outline: none"
                        v-model="new_message"
                        @keyup.enter="submitChat"
                      ></textarea>
                      <button
                        class="m-2"
                        style="outline: none"
                        @click="submitChat"
                      >
                        <svg
                          class="
                            svg-inline--fa
                            text-green-400
                            fa-paper-plane fa-w-16
                            w-12
                            h-12
                            py-2
                            mr-2
                            mb-2
                          "
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="paper-plane"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"
                          />
                        </svg>
                      </button>
                    </div>
                    <button
                      v-if="isAdmin"
                      type="submit"
                      class="
                        ml-4
                        btn
                        inline-flex
                        items-center
                        justify-center
                        px-4
                        py-2
                        border border-transparent
                        text-sm
                        font-bold
                        rounded-md
                        shadow-sm
                        text-white
                        bg-red-600
                        hover:bg-red-700
                        focus:outline-none
                        focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                        disabled:opacity-50
                        hidden
                        lg:block
                      "
                      @click="goToChatManagement"
                    >
                      Chat management
                    </button>

                    <!-- <div
                      class="
                        chat-submit-form-mobile
                        fixed
                        w-full
                        flex
                        justify-between
                        bg-white
                        border-t
                      "
                      style="bottom: 0px"
                    >
                      <textarea
                        class="
                          flex-grow
                          m-2
                          py-2
                          px-4
                          mr-1
                          rounded-full
                          border border-gray-300
                          bg-gray-200
                          focus:ring-red-500
                          focus:border-red-500
                          resize-none
                        "
                        rows="1"
                        placeholder="พิมพ์ข้อความ..."
                        style="outline: none"
                        v-model="new_message"
                        @keyup.enter="submitChat"
                      ></textarea>
                      <button
                        class="m-2"
                        style="outline: none"
                        @click="submitChat"
                      >
                        <svg
                          class="
                            svg-inline--fa
                            text-gray-500
                            fa-paper-plane fa-w-16
                            w-12
                            h-12
                            py-2
                            mr-2
                          "
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="paper-plane"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"
                          />
                        </svg>
                      </button>
                    </div> -->
                  </div>
                  <div id="tab-qa" v-if="currentTab === 'tab-qa'">
                    <div class="px-4 pt-4 pb-3">
                      <div
                        class="
                          sm:grid sm:grid-cols-3
                          sm:gap-1
                          sm:items-start
                          sm:pt-2
                        "
                      >
                        <label
                          for="question"
                          class="
                            label
                            block
                            text-sm
                            font-medium
                            text-gray-700
                            sm:mt-px
                            sm:pt-1
                          "
                        >
                          ส่งคำถาม
                        </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                          <textarea
                            v-model="new_message_qa"
                            id="question"
                            name="question"
                            rows="3"
                            class="
                              p-1
                              max-w-lg
                              shadow-sm
                              block
                              w-full
                              focus:ring-red-500
                              focus:border-red-500
                              sm:text-sm
                              border-gray-300
                              rounded-md
                            "
                            @keyup.enter="submitQA"
                          ></textarea>
                          <button
                            type="submit"
                            class="
                              mt-3
                              inline-flex
                              justify-center
                              px-4
                              py-2
                              bg-title
                              border border-transparent
                              shadow-sm
                              text-sm
                              font-medium
                              rounded-md
                              text-white
                              bg-red-600
                              hover:bg-red-700
                              focus:outline-none
                              focus:ring-2
                              focus:ring-offset-2
                              focus:ring-red-500
                              disabled:opacity-50
                            "
                            @click="submitQA()"
                            :disabled="submitQALoading || new_message_qa === ''"
                          >
                            ตกลง
                            <svg
                              v-if="submitQALoading"
                              class="animate-spin ml-2 mr-3 h-5 w-5 text-white"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                class="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                stroke-width="4"
                              ></circle>
                              <path
                                class="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="tab-poll" v-if="currentTab === 'tab-poll'">
                    <div
                      class="current-poll px-4 pt-4"
                      v-if="this.selectedPoll !== ''"
                    >
                      <h2 class="poll-title pb-5">{{ poll.title }}</h2>
                      <fieldset>
                        <div class="space-y-4">
                          <div v-if="selectedChoice !== null">
                            <div class="rounded-md bg-green-50 p-4">
                              <div class="flex">
                                <div class="flex-shrink-0">
                                  <!-- Heroicon name: solid/check-circle -->
                                  <svg
                                    class="h-5 w-5 text-green-400"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                </div>
                                <div class="ml-3">
                                  <p class="text-sm font-medium text-green-800">
                                    Thank you for voting
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else>
                            <label
                              class="
                                relative
                                block
                                rounded-lg
                                border border-gray-300
                                bg-white
                                shadow-sm
                                px-6
                                py-4
                                cursor-pointer
                                hover:border-gray-400
                                sm:flex
                                sm:justify-between
                                focus-within:ring-1
                                focus-within:ring-offset-2
                                focus-within:ring-indigo-500
                                mb-4
                              "
                              v-for="(item, index) in poll.choices"
                              :key="index"
                              :class="{
                                'choice-selected': selectedChoice === index,
                              }"
                            >
                              <input
                                type="radio"
                                name="server_size"
                                value="Hobby"
                                class="sr-only"
                                aria-labelledby="server-size-0-label"
                                aria-describedby="server-size-0-description-0 server-size-0-description-1"
                                @click="setSelectedChoice(index)"
                                :disabled="selectedChoice !== null"
                              />
                              <div class="flex items-center">
                                <div class="text-sm">
                                  <p
                                    id="server-size-0-label"
                                    class="font-medium text-gray-900"
                                  >
                                    {{ item }}
                                  </p>
                                </div>
                              </div>
                              <div
                                class="
                                  border-transparent
                                  absolute
                                  -inset-px
                                  rounded-lg
                                  border-2
                                  pointer-events-none
                                "
                                aria-hidden="true"
                              ></div>
                            </label>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div
                v-if="
                  project[0].project_slug === 'eucerin' ||
                  project[0].project_slug === 'eucerin-test'
                "
                class="btn-more"
              >
                <a
                  href="#"
                  class="btn-eucerin"
                  @click.prevent="showModalAgenda()"
                  >AGENDA</a
                >
                <a
                  :href="this.session.evaluation_url"
                  target="_blank"
                  class="btn-eucerin"
                  :disabled="this.session.evaluation !== true"
                  >EVALUATION</a
                >
              </div> -->
            </section>
            <div class="fixed bottom-0 left-0 px-4 w-full md:hidden">
              <button
                @click="logout()"
                class="
                  w-full
                  bg-title bg-red-600
                  hover:bg-red-700
                  text-white
                  font-bold
                  mt-2
                  mb-4
                  py-1
                  px-4
                  rounded-full
                "
              >
                Logout
              </button>
            </div>
          </div>
          <div
            v-if="android || tablet"
            class="
              chat-submit-form-mobile
              fixed
              w-full
              flex
              justify-between
              bg-white
              border-t
              lg:hidden
            "
            :class="[isFocus ? 'is-focus' : 'not-focus']"
          >
            <textarea
              class="
                flex-grow
                m-2
                py-2
                px-4
                mr-1
                rounded-full
                border border-gray-300
                bg-gray-200
                focus:ring-red-500
                focus:border-red-500
                resize-none
              "
              rows="1"
              placeholder="พิมพ์ข้อความ..."
              style="outline: none"
              v-model="new_message"
              @keyup.enter="submitChat"
              @focusout="focus(false)"
              @focusin="focus(true)"
            ></textarea>
            <button class="m-2" style="outline: none" @click="submitChat">
              <svg
                class="
                  svg-inline--fa
                  text-gray-500
                  fa-paper-plane fa-w-16
                  w-12
                  h-12
                  py-2
                  mr-2
                "
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="paper-plane"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"
                />
              </svg>
            </button>
          </div>
        </main>
        <div v-if="screenMd !== true" ref="scrollable" />
      </div>
    </div>
    <!-- <a-modal
      v-model="visible"
      :confirm-loading="confirmLoading"
      :width="900"
      :footer="null"
      :mask-closable="false"
    >
      <p>
        <picture>
          <source
            media="(min-width:992px)"
            :srcset="require('@/assets/img/agenda-desktop.jpg')"
          />
          <source
            media="(max-width:991px)"
            :srcset="require('@/assets/img/agenda-mobile.jpg')"
          />
          <img
            :src="require('@/assets/img/agenda-desktop.jpg')"
            alt=""
            title=""
            width="1309"
            height="719"
            loading="lazy"
            class=""
          />
        </picture>
      </p>
    </a-modal> -->
    <VueScriptComponent
      v-if="project[0]"
      :script="project[0].external_script"
    />
  </div>
</template>

<script>
import {
  isIOS,
  isTablet,
  isMobile,
  isMobileOnly,
  isAndroid,
} from 'mobile-device-detect'
import dayjs from 'dayjs'
import 'dayjs/locale/th'
import relativeTime from 'dayjs/plugin/relativeTime'
import isBetween from 'dayjs/plugin/isBetween'
import logUser from '@/mixins/logUser'
import loginCheck from '@/mixins/loginCheck'
import customCssMixins from '@/mixins/customCss.js'
// import NoSleep from 'nosleep.js'
import VueScriptComponent from 'vue-script-component'
// import axios from 'axios'
// import VClappr from 'v-clappr'

dayjs.extend(relativeTime)
dayjs.extend(isBetween)

export default {
  name: 'LiveSessionNew',
  mixins: [logUser, loginCheck, customCssMixins],
  components: { VueScriptComponent },
  metaInfo() {
    return {
      title: this.session.session_name,
    }
  },
  data() {
    return {
      android: isAndroid,
      mobile: isMobile,
      mobileonly: isMobileOnly,
      ios: isIOS,
      tablet: isTablet,
      isFocus: false,
      live_backup: true,
      newMessage: false,
      newMessageCount: 0,
      announcement: '',
      announcementEdit: false,
      selectedPlay: 'main',
      video_url: '',
      videoComponentKey: 0,
      language: 'thai',
      videoSource: 0,
      poll: {},
      selectedChoice: null,
      selectedPoll: '',
      chatTab: true,
      qaTab: false,
      pollTab: false,
      currentTab: 'tab-chat',
      currentView: 0,
      confirmSubmit: '',
      author: '',
      submitChatLoading: false,
      submitQALoading: false,
      show: false,
      load: null,
      fullPage: true,
      playerComponentKey: 0,
      isAdmin: false,
      user: {},
      messages: [],
      new_message: '',
      new_message_qa: '',
      isDesktop: window.matchMedia('(min-width: 768px)').matches,
      player: null,
      now: dayjs(),
      session: {},
      project: [],
      projectName: '',
      modalImage: '',
      visible: false,
      confirmLoading: false,
      videoHeight: 100,
      resizeInterval: null,
      options: {
        poster:
          'https://firebasestorage.googleapis.com/v0/b/livetube-portal-demo.appspot.com/o/session-picture%2Fvc-upload-1623177349608-4.jpeg?alt=media&token=ce1f31cf-a379-4576-b897-2308be116b2c',
        width: '100%',
        height: '100%',
        mute: false,
        autoplay: true,
        loop: false,
        disable_keyboard_shortcuts: false,
        disable_context_menu: true,
        mediacontrol: { seekbar: '#E113D3', buttons: '#66B2FF' },
        ga: {},
        watermark: {},
      },
    }
  },
  computed: {
    screenMd() {
      if (screen.width >= 768) {
        return true
      } else {
        return false
      }
    },
    messageFormat() {
      return this.messages
        .map((item) => {
          return item
        })
        .reverse()
    },
    sessionName() {
      return this.session.session_name
    },
    sessionDesc() {
      return this.session.session_desc
    },
    sessionStatus() {
      const session = this.session
      if (session) {
        if ('start_time' in session) {
          const startTime = dayjs(session.start_time.toDate())
          const endTime = dayjs(session.end_time.toDate())

          if (this.now.isBefore(startTime)) {
            return 1
          }

          if (this.now.isBetween(startTime, endTime)) {
            return 0
          }

          if (this.now.isAfter(endTime)) {
            return 2
          }
        } else {
          return false
        }
      }
      return false
    },
  },
  watch: {
    async session() {
      this.video_url = this.session.video_url
      this.videoComponentKey++

      this.show = true
      this.showPlayer = true

      // this.getPlayer(this.video_url)

      if (this.$isMobile()) {
        if (
          this.session.session_desc !== 'blank' &&
          this.session.session_desc !== '' &&
          this.session.session_desc !== undefined
        ) {
          this.announcementNoti()
        }
      }
    },
    // async selectedPoll() {
    //   this.selectedChoice = null
    //   if (this.selectedPoll !== '') {
    //     await this.$binding(
    //       'poll',
    //       this.$db
    //         .collection(`sessions/${this.$route.params.sessionId}/polls`)
    //         .doc(this.selectedPoll)
    //     )
    //     const pollRef = this.$rdb.ref(
    //       `poll-logs/${this.$route.params.sessionId}/${this.selectedPoll}_${localStorage.uuid}`
    //     )
    //     pollRef.on('value', (snapshot) => {
    //       const poll = snapshot.val()
    //       if (this.selectedPoll === poll.poll_id) {
    //         this.selectedChoice = poll.index
    //       }
    //     })
    //     this.currentTab = 'tab-poll'
    //   } else {
    //     this.currentTab = 'tab-qa'
    //   }
    // },
  },
  updated() {
    // if (this.newMessage === false || screen.width <= 768) {
    //   this.scroll()
    // }
    this.scroll()
  },
  async mounted() {
    // let noSleep = new NoSleep()
    setTimeout(() => {
      this.scroll()
    }, 1000)

    // setTimeout(() => {
    //   noSleep.enable()
    // }, 5000)

    this.gaTrack()
    // setInterval(() => {
    //   const source = this.player.getCurrentSource()
    //   if (this.videoSource != source) {
    //     this.videoSource = source
    //   }
    // }, 500)

    if (this.$customCssProject.includes(this.$route.params.projectSlug)) {
      await import('@/custom-css/' + this.$route.params.projectSlug + '.css')
    }
    // this.rerenderPlayer()
    // const userList = this.$rdb.ref(
    //   `view-status/${this.$route.params.sessionId}`
    // )
    // userList.on('value', (snapshot) => {
    //   let count = 0
    //   snapshot.forEach((childSnapshot) => {
    //     const childData = childSnapshot.val()
    //     if (childData.state === 'online') {
    //       count = count + 1
    //     }
    //   })
    //   this.currentView = count
    // })
  },
  async created() {
    await this.$binding(
      'project',
      this.$db
        .collection('project')
        .where('project_slug', '==', this.$route.params.projectSlug)
    )

    await this.$binding(
      'messages',
      this.$db
        .collection(`sessions/${this.$route.params.sessionId}/messages`)
        .where('deleted_at', '==', null)
        .orderBy('timestamp', 'desc')
        .limit(1000)
    )

    // if (!localStorage.getItem(`auth:live-tube-portal:${this.projectSlug}`)) {
    //   this.$router.push({
    //     name: 'LoginSCB',
    //   })
    // } else {
    //   this.load = this.$loading.show({
    //     container: this.fullPage ? null : this.$refs.formContainer,
    //     canCancel: false,
    //     color: '#fb0000',
    //     opacity: 1,
    //     transition: 'fade',
    //     width: 128,
    //     height: 128,
    //   })
    // }
    if (
      localStorage.getItem(
        `auth:live-tube-portal:${this.$route.params.projectSlug}`
      )
    ) {
      const userData = JSON.parse(
        localStorage.getItem(
          `auth:live-tube-portal:${this.$route.params.projectSlug}`
        )
      )
      this.user = userData
    }

    // const pollRef = this.$rdb.ref(`polls/${this.$route.params.sessionId}`)
    // pollRef.on('value', (snapshot) => {
    //   if (snapshot.val()) {
    //     const poll = snapshot.val()
    //     this.selectedPoll = poll.selected_poll_id
    //   }
    // })

    // const userStatusDatabaseRef = this.$rdb.ref(
    //   `view-status/scb-julius-baer-market-outlook-mid-year-2021-scb-julius-baer-market-outlook-mid-year-2021/${localStorage.uuid}`
    // )
    // const isOfflineForDatabase = {
    //   state: 'offline',
    //   last_changed: this.$firebase.database.ServerValue.TIMESTAMP,
    // }
    // const isOnlineForDatabase = {
    //   state: 'online',
    //   last_changed: this.$firebase.database.ServerValue.TIMESTAMP,
    // }
    // await this.$rdb.ref('.info/connected').on('value', function (snapshot) {
    //   if (snapshot.val() === false) {
    //     return
    //   }
    //   userStatusDatabaseRef
    //     .onDisconnect()
    //     .set(isOfflineForDatabase)
    //     .then(function () {
    //       userStatusDatabaseRef.set(isOnlineForDatabase)
    //     })
    // })

    // if (this.project[0]) {
    //   this.projectName = this.project[0].name
    //   this.projectSlug = this.project[0].project_slug
    //   this.$store.commit('changeMutation', this.project[0].logo)
    // }
    // await this.$binding(
    //   'messages',
    //   this.$db
    //     .collection(`sessions/${this.sessionId}/messages`)
    //     .where('deleted_at', '==', null)
    //     .orderBy('timestamp', 'asc')
    // )}
    await this.$binding(
      'session',
      this.$db.collection('sessions').doc(this.$route.params.sessionId)
    )

    if (this.user.role === 'admin') {
      this.isAdmin = true
    }
    // this.selectedPoll = this.session.selected_poll_id

    // if (this.session.player_type !== 'iframe') {
    // if (this.session.video_url) {
    //   await this.getPlayer(this.session.video_url)
    // }
    // }
  },
  methods: {
    focus(input) {
      if (isIOS) {
        this.isFocus = input
      }
    },
    goToNewMessage() {
      this.newMessage = false
      this.scroll()
    },
    handleChatScroll(event) {
      if (event.target.scrollTop < event.target.scrollHeight - 500) {
        this.newMessage = true
      }
      if (event.target.scrollTop === event.target.scrollHeight) {
        this.newMessage = false
      }
      // console.log(event)
    },
    gaTrack() {
      this.$ga.screenview({
        screenName: this.session.session_name,
      })
      this.$ga.set({ username: this.user.username })
    },
    async updateAnnouncement() {
      const docRef = this.$db
        .collection('sessions')
        .doc(this.$route.params.sessionId)
      await docRef.update({
        session_desc: this.announcement,
      })
      this.announcementEdit = false
    },
    setAnnouncementEdit() {
      this.announcementEdit = true
      this.announcement = this.session.session_desc
    },
    selectPlay(value) {
      if (value === 'main') {
        location.reload()
      }
      this.videoComponentKey++
      this.selectedPlay = value
    },
    selectSource(source) {
      // console.log(source)
      this.videoSource = source
      if (source === 1) {
        this.selectedPlay = 'backup'
        this.getPlayer(source)
        // this.player.setCurrentSource(source)
        // this.videoComponentKey++
      } else if (source === 0) {
        this.player.stop()
        this.selectedPlay = 'main'
        this.video_url = this.session.video_url_backup
        this.videoComponentKey++
      }
      // } else if (source === 3) {
      //   this.player = null
      //   this.selectedPlay = 'backup'
      //   this.video_url = this.session.video_url_eng_backup
      //   this.videoComponentKey++
      // }
    },
    timeFormat(timestamp) {
      // return dayjs(timestamp.toDate()).fromNow()
      if (timestamp) {
        return dayjs(timestamp.toDate()).format('DD/MM/YYYY HH:mm:ss น.')
      }
      return ''
    },
    scroll() {
      this.$refs.scrollable.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      })
    },
    logout() {
      const userData = JSON.parse(
        localStorage.getItem(
          `auth:live-tube-portal:${this.$route.params.projectSlug}`
        )
      )
      if (userData) {
        localStorage.removeItem(
          `auth:live-tube-portal:${this.$route.params.projectSlug}`
        )
        if (
          this.$route.params.projectSlug === 'eucerin' ||
          this.$route.params.projectSlug === 'eucerin-test'
        ) {
          this.$router.push({
            name: 'Register',
            params: {
              projectSlug: this.$route.params.projectSlug,
            },
          })
        } else {
          this.$router.push({
            name: 'Login',
            params: {
              projectSlug: this.$route.params.projectSlug,
            },
          })
        }
      } else {
        const currentUser = this.$auth.currentUser
        this.$auth.signOut()
        if (currentUser) {
          this.$router.push({ name: 'AdminLogin' })
        }
      }
    },
    updateLoginStatus(userData) {
      this.$db.collection('login-status').doc(userData.uid).delete()
    },
    async setSelectedChoice(index) {
      this.selectedChoice = index
      const docRef = this.$db
        .collection(`sessions/${this.$route.params.sessionId}/poll-choices`)
        .doc(`${this.selectedPoll}_${index}`)
      await docRef
        .update({
          amount: this.$firebase.firestore.FieldValue.increment(1),
        })
        .then(() => {
          console.log('Document successfully updated!')
          this.$rdb
            .ref(
              `poll-logs/${this.$route.params.sessionId}/${this.selectedPoll}_${localStorage.uuid}`
            )
            .set({
              username: localStorage.uuid,
              poll_id: this.selectedPoll,
              index: index,
              timestamp: this.$firebase.database.ServerValue.TIMESTAMP,
            })
        })
    },
    goToChatManagement() {
      this.$router.push({
        name: 'AdminChatManagement',
        params: {
          sessionId: this.$route.params.sessionId,
          projectSlug: this.$route.params.projectSlug,
        },
      })
    },
    onEnter() {
      this.submitChat()
    },
    submitChat() {
      this.submitChatLoading = true
      let role = ''
      if (this.user.role === 'admin') {
        role = 'admin'
      } else {
        role = 'user'
      }
      if (this.new_message !== '') {
        this.$db
          .collection(`sessions/${this.$route.params.sessionId}/messages`)
          .add({
            author: this.user.name,
            message: this.new_message,
            timestamp: this.$firebase.firestore.FieldValue.serverTimestamp(),
            deleted_at: null,
            star: false,
            role: role,
          })
          .then(() => {
            this.submitChatLoading = false
          })
          .catch(function (error) {
            console.error('Error writing new message to database', error)
          })
      }
      this.new_message = ''
    },
    submitQA() {
      this.submitQALoading = true
      if (this.new_message_qa !== '') {
        this.$db
          .collection(`sessions/${this.$route.params.sessionId}/qa`)
          .add({
            author: this.user.name,
            message: this.new_message_qa,
            timestamp: this.$firebase.firestore.FieldValue.serverTimestamp(),
            deleted_at: null,
            star: false,
            role: 'user',
          })
          .then(() => {
            this.submitQALoading = false
            this.$success({
              title: 'Your message has been successfully submitted',
            })
          })
          .catch(function (error) {
            console.error('Error writing new message to database', error)
          })
      }
      this.new_message_qa = ''
    },
    announcementNoti() {
      const key = `noti_${Date.now()}`
      this.$notification.open({
        message: 'ประกาศ',
        description: this.session.session_desc,
        placement: 'bottomRight',
        duration: 0,
        btn: (h) => {
          return h(
            'a-button',
            {
              props: {
                type: 'primary',
              },
              on: {
                click: () => this.$notification.close(key),
              },
            },
            'ปิด'
          )
        },
        key,
        onClose: close,
      })
    },
    relativeTime(time) {
      dayjs.locale(this.$i18n.locale)
      if (time) {
        return dayjs().to(time.toDate())
      }
      return ''
    },
    showModalAgenda() {
      this.visible = true
    },
    showModalDay1() {
      this.modalImage = require('@/assets/img/day1.jpg')
      this.visible = true
    },
    showModalDay2() {
      this.modalImage = require('@/assets/img/day2.jpg')
      this.visible = true
    },
    showModalDay3() {
      this.modalImage = require('@/assets/img/day3.jpg')
      this.visible = true
    },
    // getPlayer(source = null) {
    //   if (this.player !== null) {
    //     this.player.stop()
    //   }
    //   this.player = null
    //   try {
    //     // let videoUrl = this.session.video_url
    //     // let videoUrlEng = this.session.video_url_eng

    //     // var list = []
    //     // if (source === null || source === 1) {
    //     //   list = JSON.parse(videoUrl)

    //     //   if (
    //     //     this.session.status === 'landing_page_video' ||
    //     //     this.session.status === 'live_end_video'
    //     //   ) {
    //     //     list = [
    //     //       {
    //     //         file: this.session.landing_page_video_url,
    //     //         label: 'Auto',
    //     //       },
    //     //     ]
    //     //   }
    //     // } else {
    //     //   list = [
    //     //     {
    //     //       file: videoUrl,
    //     //       label: 'Auto',
    //     //     },
    //     //   ]
    //     // }
    //     // setTimeout(() => {
    //     //   this.player = window.OvenPlayer.create('video', {
    //     //     autoStart: true,
    //     //     loop: true,
    //     //     sources: list,
    //     //   })
    //     // }, 100)
    //   } catch (error) {
    //     console.log(error.response.data)
    //   }
    // },
  },
}
</script>

<style lang="scss" scoped>
.is-focus {
  bottom: 50px;
}

.not-focus {
  bottom: 0px;
}

.new-message-badge {
  cursor: pointer;

  span {
    z-index: 100;
  }

  // padding: 10px 0px 10px 0px;
  margin: -30px 0px 0px 0px;
}

.iframe-container {
  background-color: #fff;
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}

span {
  font-family: 'anuphan';
}

.title {
  font-size: 1.6em;
  font-weight: bold;
  font-family: 'anuphan';
}

.title-mobile {
  font-weight: bold;
  font-family: 'anuphan';
}

.chat-submit-form {
  display: block;

  @media (max-width: 1024px) {
    display: none;
  }
}

.chat-submit-form-mobile {
  // position: -webkit - sticky; /*for safari*/
  z-index: 100;
  border-color: #00afef;

  svg {
    color: #00afef;
  }
}

.chat-submit-form-desktop,
.chat-submit-form-tablet {
  textarea {
    &:focus {
      --tw-ring-opacity: 1;
      --tw-ring-color: #00afef;
      border-color: #00afef;
    }
  }

  border-color: #00afef;

  svg {
    color: #00afef;
  }
}

.bottom-f {
  bottom: 50px;
}

.bottom-z {
  bottom: 0px;
}

header {
  display: block;

  @media (max-width: 768px) {
    display: none;
  }
}

.btn {
  font-family: 'anuphan';
}

.chat-submit-form {
  @media (orientation: portrait) {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #c5c5c5;
  }
}

.chat-message-box {
  overflow-y: scroll;

  @media (min-width: 768px) {
    height: 320px;
  }

  @media (min-width: 1024px) {
    height: 320px;
  }

  @media (min-width: 1280px) {
    height: 380px;
  }
}

.chat-message-container {
  z-index: 0;
}

.choice-selected {
  background-color: rgb(255, 166, 166);
}

.agenda-box {
  overflow-y: scroll;
  height: 300px;
}

.qa-form {
  label,
  button,
  input,
  textarea {
    font-size: 1.2em;
  }
}

#player {
  width: 100%;
  height: 100%;
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
}

.btn-chat-manage {
  margin: 0px 0px 0px 12px;
}

.user {
  float: left;
  color: #00a0da;
  margin: 0px 6px 0px 0px;
}

// .chat-row {

.chat-message {
  display: inline-block;
  width: 100%;
  font-size: 1.2em;
  color: rgba(0, 0, 0, 0.8);

  @media (min-width: 768px) {
    font-size: 1.1em;
  }
}

.chat-row {
  .user {
    float: left;
    color: #050505;
    margin: 0px 6px 0px 0px;
    font-weight: 600;
  }

  &.admin {
    .user {
      float: left;
      color: #00a0da;
      margin: 0px 6px 0px 0px;
    }
  }

  font-size: 14px;

  @media (min-width: 768px) {
    padding: 0px 5px;
  }

  .reply {
    .reply-label {
      font-size: 10px;
      width: 90%;
      text-align: right;
    }

    .original-message {
      font-size: 12px;
      padding: 6px 8px;
      width: 90%;
      background: #e4e6eb;
      color: #65676b;
    }
  }

  .original-message {
    display: inline-block;
    min-height: 36px;
    font-size: 12px;

    @media (max-width: 768px) {
      font-size: 14px;
    }

    background-color: #f0f2f5;
    width: 100%;
    padding: 8px 8px;
    border-radius: 10px;
  }

  .reply-message {
    display: inline-block;
    font-size: 12px;

    @media (max-width: 768px) {
      font-size: 14px;
    }

    padding: 8px 8px;
    border-radius: 10px;
    background-color: #f0f2f5;
    width: 100%;
    margin: -10px 0px 0px 0px;
  }

  .time {
    color: #9a9a9a;
    font-size: 10px;
    display: block;
    float: left;
    margin: 4px 0px 0px 0px;
  }
}

.ant-list-split .ant-list-item {
  border-bottom: none;
  padding: 6px 0px 6px 0px;

  .ant-comment-inner {
    padding: 0px;
  }
}

.current-view {
  display: none;
}

.section-header {
  margin-left: 0;
  margin-top: 0;
  padding-bottom: 3px;
  font-family: 'anuphan';
  font-weight: bold;
  font-size: 1.3em;
  border-bottom: 1px solid #cbced1;
}

.desktop-header {
  margin-top: 24px;
}

.ant-modal {
  z-index: 10000;
}

.ant-modal-body {
  padding: 0;

  img {
    width: 100%;
  }
}

.ant-modal-close {
  color: #fff;
  z-index: 1000;
}

@media (max-width: 768px) {
  .ant-page-header.ant-page-header-ghost {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
  }
}

.mobile-video-space {
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
}

@media (max-width: 768px) {
  .video-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
  }
}

.button-agenda {
  margin-left: 10px;
  background-color: #f0f0f0;
  color: #333;
  border: 0;
  text-shadow: none;
  border-radius: 0;

  &:hover {
    background-color: #ff1493;
    color: #fff;
  }
}

.ant-tag {
  margin-top: 6px;
  font-family: 'anuphan';
  font-size: 0.9em;
}

@media (max-width: 768px) {
  .session-divider {
    display: none;
  }
}

.session-name {
  font-family: 'anuphan';
  font-weight: 600;
  text-transform: uppercase;
}

.session-name-mobile {
  margin-top: 10px;
  margin-bottom: 0;
  font-family: 'anuphan';
  font-size: 1.4em;
  font-weight: 600;
  text-transform: uppercase;
}

.status {
  text-align: right;
}

.status .ant-tag:last-child {
  margin-right: 0;

  @media (max-width: 768px) {
    margin-top: 14px;
    margin-bottom: 14px;
  }
}

.description {
  @media (min-width: 770px) {
    display: block;
  }
}

.video-container {
  width: 100%;

  // padding-top: 56.25%;
  @media (max-width: 768px) {
    position: -webkit - sticky;
    /*for safari*/
    position: fixed;
    z-index: 1;
    top: 0;
  }
}

#video {
  width: 100%;
  height: 100%;
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
}

.ant-alert {
  margin-bottom: 15px;
  border-radius: 0;
}

.ant-alert-message {
  font-family: 'anuphan';
  font-weight: 600;
  text-transform: uppercase;
}

.ant-alert-description {
  line-height: 1.5em;
  font-family: 'anuphan';
  font-weight: 400;
}

.ant-alert-with-description {
  padding: 7px 9px 10px 64px;
}

@media only screen and (min-width: 768px) {
  #live-session {
    padding-top: 15px;
  }

  .ant-divider {
    display: none;
  }
}

.ant-notification-notice-message {
  font-size: 1.3em;
  font-weight: 700;
}

.ant-notification-notice {
  margin-bottom: 0;
  font-family: 'anuphan';
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 0;
}

@media (max-width: 768px) {
  .ant-notification-bottomRight {
    right: 16px !important;
    margin-right: 0;
  }

  .ant-notification-notice-close {
    display: none;
  }
}

.main-screen {
  min-height: calc(100vh - 4rem);
}

$main-color: #1a2d57;
$main-font: 'db_airystd';
$med-font: 'db_airystd_med';

// input,
// textarea {
//   font-family: 'anuphan';
// }

.bg-title {
  font-family: 'anuphan';
}

.label {
  font-family: 'anuphan';
}

.media {
  position: relative;
  padding-bottom: 56.25%;
  /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  // .video {
  //   width: 100%;
  //   height: 100%;
  // }
}

$black: #202020;
$white: #fff;
$dark-grey: #808080;
$light-grey: #d9d9d9;
$purple: #b97cfc;
$pink: #f93b69;
$green: #7f673d;
$orange: #eb8b6e;

.timeline {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;

    &::before {
      content: ' ';
      height: 100%;
      width: 1px;
      background-color: $light-grey;
      position: absolute;
      top: 0;
      left: 0.42em;
      z-index: 0;
    }
  }

  li div {
    display: inline-block;
    margin: 1em 0 0;
    vertical-align: top;
  }

  .bullet {
    position: absolute;
    width: 1em;
    height: 1em;
    box-sizing: border-box;
    border-radius: 50%;
    background: $white;
    z-index: 2;
    margin-right: 1em;

    &.pink {
      border: 2px solid $pink;
    }

    &.green {
      border: 2px solid $green;
    }

    &.orange {
      border: 2px solid $orange;
    }
  }

  .time {
    width: 19%;
    margin-left: 25px;
    font-family: $main-font;
    font-size: 0.75em;
    padding-top: 0.5em;

    @media (max-width: 1210px) {
      width: 19%;
    }
  }

  .desc {
    width: 68%;

    &.-no-text {
      margin-bottom: -15px;
    }

    @media (max-width: 1210px) {
      width: 60%;
    }
  }

  h3 {
    font-family: $med-font;
    font-size: 1.2em;
    font-weight: 400;
    line-height: 1.1em;
    margin: 0 0 3px;
    color: #1a2d57;
  }

  h4 {
    margin: 3px 0 0;
    font-family: $main-font;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.2em;
    color: $dark-grey;
  }

  .people img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
}

.credits {
  left: 10px;
}

.video {
  right: 10px;
}

.poll-title {
  font-family: 'anuphan';
  font-weight: 600;
  font-size: 1.4em;
}

#tab-poll {
  label {
    font-family: 'anuphan';
  }
}

.inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}
</style>
